import React from "react";
import Layout from "../components/Layout";
import Section from "../components/Section";

const NotFoundPage = () => (
  <Layout>
    <Section background>
      <div className="prose text-center mx-auto">
        <h1 className="text-2xl">Page not found</h1>
        <p>We were unable to find the page your requested.</p>
      </div>
    </Section>
  </Layout>
);

export default NotFoundPage;
